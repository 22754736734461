/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes, { object } from 'prop-types';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Row, Col, Container } from 'react-grid-system';
// Local UI components
import SingleNewsPreview from '../../../shared/UIKit/SingleNewsPreview';
// API
import { API_UPLOADS } from '../../../shared/ApIs';
// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function MoreNews({ pageContent }) {
  /* ********************************** HOOKS ********************************* */

  // localization
  const { t } = useTranslation();
  const { title1 } = t('moreNews', { returnObjects: true });

  /* ********************************** RENDER HELPERS ********************************* */

  const Newsmap = pageContent?.map((news) => {
    const {
      id,
      attributes: { title, shortDescription, image },
    } = news;
    return (
      <Col key={id} sm={12} lg="content">
        <SingleNewsPreview
          title={title}
          description={shortDescription}
          buttonValue={title1}
          PreviewImage={API_UPLOADS(image?.data?.attributes?.url)}
          id={id}
        />
      </Col>
    );
  });

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="more-news">
      <span className="more-news-title">{title1}</span>
      <Row align="stretch" justify="start">
        {Newsmap}
      </Row>
    </Container>
  );
}

MoreNews.propTypes = {
  pageContent: PropTypes.objectOf(object.isRequired).isRequired,
};

MoreNews.defaultProps = {};

export default MoreNews;
