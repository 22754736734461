/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import PropTypes, { object } from 'prop-types';
// UI lib comoponents
import { useTranslation } from 'react-i18next';
// Local UI components
import DynamicImage from '../../../shared/UIKit/DynamicImage';
// APISHelmet
import { API_UPLOADS } from '../../../shared/ApIs';
// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function DetailsSection({ pageContent }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // State

  // Other hooks like reux or localization ...

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ***************************** RENDER HELPERS ***************************** */
  // localization
  const { t } = useTranslation();
  const { monthNamelist, shared } = t('moreNews', { returnObjects: true });

  function RenderNewsTitle() {
    return (
      <section className="title-section">
        <h1>{pageContent?.attributes?.title}</h1>
      </section>
    );
  }
  function RenderIntroSection() {
    return (
      <section className="intro-section">
        <p>{pageContent?.attributes?.shortDescription}</p>
        <div className="More-details-container">
          <h4 className="More-details-container-date">
            {pageContent?.attributes?.date
              .split('-')
              .reverse()
              .join('-')
              .replace(
                pageContent?.attributes?.date.split('-').splice(1, 1),
                monthNamelist[
                  pageContent?.attributes?.date.split('-').splice(1, 1) - 1
                ],
              )}
          </h4>
          <div className="More-details-container-social-media">
            <span>{shared}</span>
            <div className="More-details-container-social-media-icons">
              <FacebookShareButton
                url={`https://preview.exactt-global-partners.eyespot.agency/news-details/${pageContent?.id}`}
              >
                <DynamicImage
                  path="shared-images"
                  fileName="facebook.svg"
                  alt="share-social-media"
                />
              </FacebookShareButton>
              <LinkedinShareButton
                url={`https://preview.exactt-global-partners.eyespot.agency/news-details/${pageContent?.id}`}
              >
                <DynamicImage
                  path="shared-images"
                  fileName="instagram.svg"
                  alt="share-social-media"
                />
              </LinkedinShareButton>
              <TwitterShareButton
                url={`https://preview.exactt-global-partners.eyespot.agency/news-details/${pageContent?.id}`}
              >
                <DynamicImage
                  path="shared-images"
                  fileName="twitter.svg"
                  alt="share-social-media"
                />
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </section>
    );
  }
  function RenderBodyImage() {
    return (
      <section className="News-image-section">
        <img
          src={API_UPLOADS(
            pageContent?.attributes?.image?.data?.attributes?.url,
          )}
          alt="news"
        />
      </section>
    );
  }
  function RenderBodySection() {
    return (
      <section className="body-section">
        <p>{pageContent?.attributes?.description}</p>
      </section>
    );
  }
  function RenderFinalSection() {
    return <section className="final-section" />;
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <div className="news-details">
      <RenderNewsTitle />
      <RenderIntroSection />
      <RenderBodyImage />
      <RenderBodySection />
      <RenderFinalSection />
    </div>
  );
}

DetailsSection.propTypes = {
  pageContent: PropTypes.objectOf(object.isRequired).isRequired,
};

DetailsSection.defaultProps = {};

export default DetailsSection;
