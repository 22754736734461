/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

import PropTypes from 'prop-types';
// Lib UI components
import axios from 'axios';
// Local UI components
import { useI18next } from 'gatsby-plugin-react-i18next';
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import DetailsSection from '../page-components/NewsDetails/DetailsSection';
import MoreNews from '../page-components/NewsDetails/MoreNews';
import { API_NEWS_ID, API_NEWS_ID_FILTRED, API_UPLOADS } from '../shared/ApIs';
// Style
import '../page-styles/NewsDetails.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function NewsDetails({ id }) {
  /* ********************************** HOOKS ********************************* */

  const { language } = useI18next();

  const locale = language === undefined ? 'fr' : language;

  const [newDetails, setNewDetails] = React.useState();

  /* --------------------------------- HELPERS -------------------------------- */
  async function getNewDetails() {
    const { data } = await axios(API_NEWS_ID(id, locale));
    setNewDetails(data);
  }
  React.useEffect(() => {
    getNewDetails();
  }, []);

  // State
  const [newsListFiltred, setNewsListFiltred] = React.useState();
  // Effect
  async function getProjects() {
    const { data } = await axios(API_NEWS_ID_FILTRED(id, locale));
    setNewsListFiltred(data);
  }

  React.useEffect(() => {
    getProjects();
  }, []);
  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo
        title="News details"
        imgUrl={API_UPLOADS(
          newDetails?.data?.attributes?.image?.data?.attributes?.url,
        )}
      />
      <div>
        <DetailsSection pageContent={newDetails?.data} />
        <MoreNews pageContent={newsListFiltred?.data} />
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["NewsDetails", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

NewsDetails.propTypes = {
  id: PropTypes.number.isRequired,
};
NewsDetails.defaultProps = {};

export default NewsDetails;
